'use strict';


angular.module('kljDigitalLibraryApp')

.controller('HomeCtrl', ["$rootScope", "$scope", "$state", "commonVariables",
    "appConfig", "HomeService", "ngToast", "IndexService", "$window",
    function($rootScope, $scope, $state, commonVariables,
        appConfig, HomeService, ngToast, IndexService, $window) {


        var init = function() {

            commonVariables.isHome = true;
            $rootScope.title = "Home";
            if (!$rootScope.offlineApp) getNews();
            $scope.showInstruction = true;
            setTimeout(function() {
                $scope.showInstruction = false;
                $scope.$apply();
            }, 30000);
        }

        $scope.enableInstruction = function(flag) {
            $scope.showInstruction = flag;
        }
        $rootScope.$broadcast('home', true);
        // angular.element(document).ready(function () {
        //     		$('#myModal').modal('show');
        //  	});

        // const RESPONSE_FIELDS = "title,text"
        // $scope.totalNews = PaginationService.getCount();

        // $scope.jsonBody = {
        //     query: {
        //         pageCount: appConfig.PAGINATION_LIMIT,
        //         pageNumber: 1,
        //         fields: RESPONSE_FIELDS
        //     }
        // };

        var getNews = function() {
            HomeService.getNewsMarquee(appConfig.PAGINATION_LIMIT)
                .then(function(news) {
                    let active_news = [];
                    let l = news.length;
                    for(let i=0; i<l; i++){
                        if(news[i].is_active){
                            active_news.push(news[i]);
                        }
                    }
                        $scope.news = active_news;
                        // $scope.totalNews = appConfig.PAGINATION_LIMIT;
                        // PaginationService.setCount($scope.totalNews);
                    },
                    function(error) {
                        console.log("error-->", error);
                    });
        }

        init();

        var getParamsObject = function(dataReceived) {

            console.log("dataReceived-->", dataReceived);

            var paramsObject = {};
            for (var key in dataReceived) {
                if (typeof(dataReceived[key]) == 'object') {

                    for (var subkey in dataReceived[key]) {

                        if (dataReceived[key][subkey] instanceof Date) {
                            //paramsObject[key + "_" + subkey] = dataReceived[key][subkey].getDay() + '-' + dataReceived[key][subkey].getMonth() + '-' + dataReceived[key][subkey].getYear();
                            paramsObject[key + "_" + subkey] = dataReceived[key][subkey].getFullYear() + '-' + (dataReceived[key][subkey].getMonth() + 1) + '-' + dataReceived[key][subkey].getDate();
                            continue;
                        }

                        if ('citation' === key) {

                            paramsObject[key + "_" + subkey] = parseInt(dataReceived[key][subkey]);

                        } else if ('party' === key) {

                            paramsObject[key + "_" + subkey + "_name"] = dataReceived[key][subkey]['name'];
                        } else {

                            paramsObject[key + "_" + subkey] = dataReceived[key][subkey];
                        }

                    }

                } else {
                    paramsObject[key] = dataReceived[key];
                }
            }

            console.log("paramsObject-->", paramsObject);

            return paramsObject;
        }


        $scope.goToCaseList = function(search) {
            if (search) {
                saveSearch(search);
                $state.go("index.case-list", { searchValue: search, orderOfFilters: "1," });
            } else {
                // $scope.FormError = true;
                // setTimeout(function(){
                //     $scope.FormError = false;
                // },3000);
                ngToast.danger("Please enter text to search");
            }

        }

        //on change in search box
        $scope.searchCases = function(value) {
            // if(value.length > 1){
            //     $state.go("index.case-list",{searchValue:value,pageno:2});
            // }
        }

        var saveSearch = function(search) {
            var body = {
                query: search,
                type: "CASE",
                category: $scope.appTitle
            };
            IndexService.saveHistory(body)
                .then(function(data) {
                    // $scope.news = news;
                    // $scope.totalNews = appConfig.PAGINATION_LIMIT;
                    // PaginationService.setCount($scope.totalNews);
                })
                .catch(function(error) {
                    console.log("error-->", error);
                });
        }

        $scope.$on('applyFilter', function(event, dataReceived) {

            var paramsObject = getParamsObject(dataReceived);
            paramsObject.searchValue = 'EMPTY_STRING';
            $state.go("index.case-list", paramsObject);

        });


    }
]);